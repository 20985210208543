@tailwind base;
@tailwind components;
@tailwind utilities;

.hgic-input {
    @apply block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring;
  }

  .flow-input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
  }
  
  .hgic-btn {
    @apply px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600;
  }
  
  .toggler{
    @apply relative w-10 mr-2 h-5 transition-all duration-200 ease-in-out bg-gray-400 rounded-full shadow-inner outline-none appearance-none cursor-pointer 
  }
  
  .btn {
    @apply py-2 px-2 text-sm mr-2 rounded-md shadow-sm focus:outline-none transition ease-out duration-500
  }
  
  .btn-red{
   @apply btn bg-gradient-to-r from-red-500 to-pink-500 hover:from-pink-700 hover:to-red-700  
  }
  
  .btn-blue {
    @apply btn bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-700 hover:to-indigo-700
  }
  .btn-yellow {
    @apply btn bg-gradient-to-r from-yellow-500 to-orange-500 hover:from-orange-700 hover:to-yellow-700
  }
  
  .btn-green {
    @apply btn bg-gradient-to-r from-green-500 to-green-500 hover:from-green-700 hover:to-green-700
  }